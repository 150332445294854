export const getWindowOptions = function() {
  const width = 500
  const height = 350
  const left = window.innerWidth / 2 - width / 2
  const top = window.innerHeight / 2 - height / 2

  return [
    'resizable,scrollbars,status',
    'height=' + height,
    'width=' + width,
    'left=' + left,
    'top=' + top,
  ].join()
}
