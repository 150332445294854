//@flow
/* global FB */
import * as React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import Icon, { ICONS } from '../../icons'
import { getWindowOptions } from './twitter.js'
export type SocialButtonPropTypes = {
  url: string,
}

const Background = styled.button`
  border-radius: 50%;
  outline: none;
  &:focus {
    box-shadow: 0px 0px 1px 1px
      ${props => themeGet(`colors.${props.FocusRingColor}`, 'blue')};
  }
  position: relative;
  height: 3em;
  width: 3em;
  ${space};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    z-index: -1;
    transform: scale(0.25);
    opacity: 0;
    transition: transform 0.1s, opacity 0.1s;
    will-change: transform, opacity;
    ${color};
    ${props =>
      props.hover &&
      `
    transform: scale(1);
    opacity: 1;
    `}
  }
`

const Symbol = styled(Icon)`
  transform: scale(1);
  opacity: 0.9;
  transition: transform 0.1s, opacity 0.1s;
  will-change: transform, opacity;
  ${props => {
    return (
      props.hover &&
      `
    transform: scale(1.025);
    opacity: 1;
    `
    )
  }}
`

type SocialButtonProps = {
  onClick: () => mixed,
  symbol: string,
  title: string,
  description: string,
}

function SocialButton({
  onClick,
  symbol,
  title,
  description,
  ...props
}: SocialButtonProps) {
  const [hover, setHover] = React.useState()
  return (
    <Background
      bg="primaryShades.20"
      FocusRingColor="secondaryShades.30"
      hover={hover}
      onClick={() => onClick()}
      onMouseDown={e => e.preventDefault()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
      pb={[4, 4, 4, 0]}
    >
      <Symbol
        {...{ title, description, symbol, hover }}
        size={2}
        fill={hover ? 'primaryShades.80' : 'primaryShades.60'}
      />
    </Background>
  )
}

export function FacebookButton({ url, ...props }: SocialButtonPropTypes) {
  const handleClick = () => {
    //$FlowFixMe
    FB.ui(
      {
        method: 'share',
        href: url || window.location.href,
      },
      function(response) {
        if (response && response.isError) console.warn(response)
      }
    )
  }

  return (
    <SocialButton
      symbol={ICONS.FACEBOOK}
      onClick={handleClick}
      title="share this page on Facebook"
      description="Facebook Icon"
      {...props}
    />
  )
}

const text = encodeURIComponent(
  'Help spread the word about B&W services with a tweet'
)

export function TwitterButton(props: mixed) {
  function handleClick() {
    if (typeof window !== 'undefined') {
      const shareUrl =
        'https://twitter.com/intent/tweet?url=' +
        location.href +
        '&text=' +
        text
      const win = window.open(shareUrl, 'ShareOnTwitter', getWindowOptions())
      win.opener = null
    }
  }
  return (
    <SocialButton
      symbol={ICONS.TWITTER}
      onClick={handleClick}
      title="Tweet this page"
      description="Twitter"
      {...props}
    />
  )
}
