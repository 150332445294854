//@flow
import * as React from 'react'
import { graphql } from 'gatsby'

import SliceZone, {
  type SliceZoneData,
} from '../components/containers/sliceZone'

import Layout from '../components/layout'
import {
  FlexColumnGrid,
  Background,
  LayoutBox,
  FullWidthBox,
} from '../components/containers'
import Article from '../components/molecules/richTextArticle'

import Hero, {
  modelServiceHero,
  type ServiceHeroData,
} from '../components/organisms/hero'
import SocialMediaBar from '../components/molecules/socialMediaBar'
import { Box } from '../components/atoms/cards'
import { type RichTextAry } from '../types/flow/gatsby-source-prismic-graphql'

import { type PageMeta } from '../components/containers/seoData'

export type ServicesData = {|
  prismic: {|
    allServicess: {|
      edges: {|
        node: {|
          ...ServiceHeroData,
          _meta: {|
            id: string,
            uid: string,
            type: string,
          |},
          theme: {|
            _meta: {|
              id: string,
              uid: string,
              type: string,
            |},
          |},
          article_sections: {|
            section: RichTextAry,
          |}[],
          body: SliceZoneData,
          seo_data: PageMeta[],
        |},
      |}[],
    |},
  |},
|}

function Services({ data, ...props }: { data: ServicesData }) {
  const doc = data.prismic.allServicess.edges.slice(0, 1).pop()
  if (!doc) return null
  //$FlowFixMe
  const heroData = modelServiceHero(doc.node)
  let slices = Array.isArray(doc.node.body) && [...doc.node.body]
  let articleSections = Array.isArray(doc.node.article_sections) && [
    ...doc.node.article_sections.map(a => a.section),
  ]
  const { uid } = doc.node._meta
  return (
    <Layout
      path={uid}
      themeName={doc.node.theme._meta.uid}
      seoData={doc.node.seo_data.pop()}
    >
      <Hero pt={[10]} pb={[9]} data={heroData}></Hero>
      <Background>
        <Box>
          <LayoutBox>
            <FlexColumnGrid columns={12} spaceBetween={3}>
              <Box colSpan={[12, 12, 12, 10, 8]} nudge={[0, 0, 0]}>
                <Article pt={8} pb={4}>
                  {//$FlowFixMe
                  articleSections && articleSections.shift()}
                </Article>
                <FullWidthBox>
                  {slices && <SliceZone {...props}>{slices.shift()}</SliceZone>}
                </FullWidthBox>
                <Article pt={[0, 0, 0, 6]} pb={[0, 0, 0, 4]} gap={[2, 2, 2, 3]}>
                  {//$FlowFixMe
                  articleSections && articleSections}
                </Article>
              </Box>
              <SocialMediaBar
                flexDirection={['row', 'row', 'row', 'column']}
                position={['relative', 'relative', 'relative', 'sticky']}
                colSpan={[12, 12, 12, 1, 2]}
                pt={[0, 0, 0, 8]}
                pb={3}
                top={0}
                ml={['-0.5em', '-0.5em', '-0.5em', 0]}
                url={`https://www.bwpropertycare.com/${uid}`}
                order={[1, 1, 1, -1]}
                height={[0, 0, 0, '100%']}
              />
              <FullWidthBox order={2} colSpan={12}>
                {slices && <SliceZone>{slices}</SliceZone>}
              </FullWidthBox>
            </FlexColumnGrid>
          </LayoutBox>
        </Box>
      </Background>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServicesQuery($uid: String! = "damp-proofing") {
    prismic {
      allServicess(uid: $uid) {
        edges {
          node {
            _meta {
              id
              uid
            }
            theme {
              ... on PRISMIC_Theme {
                _meta {
                  uid
                }
              }
            }
            article_sections {
              section
            }
            main_image
            main_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            seo_data {
              meta_description
              meta_title
              opengraph_description
              opengraph_image
              opengraph_locale
              opengraph_site_name
              opengraph_title
              schema
              twitter_card_type
              twitter_site
            }
            service_name
            short_description
            body {
              ... on PRISMIC_ServicesBodyCta {
                type
                primary {
                  button_text
                  section_title
                  description
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    _linkType
                    ... on PRISMIC_Four_oh_four {
                      _meta {
                        uid
                        id
                        type
                      }
                    }
                    ... on PRISMIC_About {
                      _meta {
                        uid
                        id
                        type
                      }
                    }
                    ... on PRISMIC_Legal {
                      _meta {
                        uid
                        id
                        type
                      }
                    }
                    ... on PRISMIC_Contact {
                      _meta {
                        uid
                        id
                        type
                      }
                    }
                    ... on PRISMIC_Services {
                      _meta {
                        uid
                        id
                        type
                      }
                    }
                  }
                  testimonial {
                    ... on PRISMIC_Testimonials {
                      name
                      location
                      testimonial
                    }
                  }
                }
                label
              }
              ... on PRISMIC_ServicesBodyHighlights_row {
                type
                fields {
                  description
                  heading
                }
              }
              ... on PRISMIC_ServicesBodyLocations {
                type
                fields {
                  locations {
                    ... on PRISMIC_Locations {
                      location
                      coordinates
                    }
                  }
                }
                label
                primary {
                  leading_sentence
                }
              }
              ... on PRISMIC_ServicesBodyPromotion {
                type
                primary {
                  background_image
                  background_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  button_text
                  description
                  heading
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Services
