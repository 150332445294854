//@flow

import * as React from 'react'
import { Flex } from '../../atoms/cards'

import { FacebookButton, TwitterButton } from '../../atoms/buttons/socialButton'

type SocialMediaBarProps = {
  url: string,
}

function SocialMediaBar({ url, ...props }: SocialMediaBarProps) {
  return (
    <Flex {...props}>
      <FacebookButton url={url} />
      <TwitterButton url={url} />
    </Flex>
  )
}

export default SocialMediaBar
